export enum Permission {
  // Side Menu
  AccessDashboard = "AccessDashboard",
  AccessContracts = "AccessContracts",
  AccessRequirements = "AccessRequirements",
  AccessCalendar = "AccessCalendar",
  AccessInterviews = "AccessInterviews",
  AccessCompanyProfile = "AccessCompanyProfile",
  AccessRoleManagement = "AccessRoleManagement",
  AccessProjects = "AccessProjects",
  AccessRiskManagement = "AccessRiskManagement",
  AccessEscalationManagement = "AccessEscalationManagement",
  AccessProjectFeedback = "AccessProjectFeedback",
  AccessValueAndOpportunity = "AccessValueAndOpportunity",
  AccessTaskManagement = "AccessTaskManagement",
  AccessHelpdesk = "AccessHelpdesk",
  AccessFileStorage = "AccessFileStorage",
  AccessProjectReports = "AccessProjectReports",
  AccessTMarket = "AccessTMarket",
  AccessProjectBilling = "AccessProjectBilling",

  // Dashboard
  ViewDashboard = "ViewDashboard",
  ViewProfileAction = "ViewProfileAction",
  ViewContractAction = "ViewContractAction",
  ViewRequirementAction = "ViewRequirementAction",
  ViewInterviewAction = "ViewInterviewAction",

  // Contract
  ViewContracts = "ViewContracts",
  AccessIndividualContract = "AccessIndividualContract",
  CreateContractAction = "CreateContractAction",
  SignContractAction = "SignContractAction",
  PaymentAction = "PaymentAction",

  // Requirement
  ViewRequirements = "ViewRequirements",
  AddRequirementAction = "AddRequirementAction",
  EditRequirementNameAction = "EditRequirementNameAction",
  AddNewTalentInMyTeamAction = "AddNewTalentInMyTeamAction",
  AccessMyTeam = "AccessMyTeam",
  DeleteTeamMemberInMyTeamAction = "DeleteTeamMemberInMyTeamAction",
  ScheduleInterviewInMyTeamAction = "ScheduleInterviewInMyTeamAction",
  RecheduleInterviewInMyTeamAction = "ReScheduleInterviewInMyTeamAction",
  CancelInterviewInMyTeamAction = "CancelInterviewInMyTeamAction",
  HireInMyTeamAction = "HireInMyTeamAction",
  RejectInMyTeamAction = "RejectInMyTeamAction",
  SecondRoundInMyTeamAction = "SecondRoundInMyTeamAction",
  AddContractInMyTeamAction = "AddContractInMyTeamAction",
  AccessParticularRequirement = "AccessParticularRequirement",
  ViewEmployeeProfileAction = "ViewEmployeeProfileAction",
  AccessCart = "AccessCart",

  // Calendar
  ViewCalendar = "ViewCalendar",
  ViewInterviewDetailsAction = "ViewInterviewDetailsAction",
  ViewContractDetailsAction = "ViewContractDetailsAction",

  // Interview
  ViewInterviews = "ViewInterviews",
  RescheduleInterviewAction = "RescheduleInterviewAction",
  AccessVideoConference = "AccessVideoConference",
  HireAction = "HireAction",
  RejectAction = "RejectAction",
  CancelInterview = "CancelInterview",
  SecondRoundAction = "SecondRoundAction",

  // Company Profile
  ViewCompanyProfile = "ViewCompanyProfile",
  AccessEditCompanyProfile = "AccessEditCompanyProfile",
  PublishCompanyProfileAction = "PublishCompanyProfileAction",
  EditPrimaryDetailsAction = "EditPrimaryDetailsAction",
  EditExecutiveDetailsAction = "EditExecutiveDetailsAction",
  EditCompanyDetailsAction = "EditCompanyDetailsAction",
  EditCompanyDocumentsAction = "EditCompanyDocumentsAction",
  EditCertificationsAction = "EditCertificationsAction",

  // Role Management
  ViewRoleManagement = "ViewRoleManagement",
  AddSubUserAction = "AddSubUserAction",
  EditSubUserAction = "EditSubUserAction",
  DeleteSubUserAction = "DeleteSubUserAction",
  ResendSubUserInviteLinkAction = "ResendSubUserInviteLinkAction",
  ActivateSubUserAction = "ActivateSubUserAction",
  DeactivateSubUserAction = "DeactivateSubUserAction",

  // Project Management
  // Projects
  ViewProjects = "ViewProjects",
  ViewProjectDetails = "ViewProjectDetails",
  ViewMilestoneDetails = "ViewMilestoneDetails",
  ViewTaskDetails = "ViewTaskDetails",
  UpdateStackholderDetails = "UpdateStackholderDetails",

  // Risk Management
  AddRiskAction = "AddRiskAction",
  ViewRiskAction = "ViewRiskAction",
  DeleteRiskAction = "DeleteRiskAction",
  EditRiskAction = "EditRiskAction",

  // Escalation Management
  ViewEscalationAction = "ViewEscalationAction",
  AddEscalationAction = "AddEscalationAction",
  EditEscalationAction = "EditEscalationAction",
  DeleteEscalationAction = "DeleteEscalationAction",


  // Project Feedback
  ViewFeedback = "ViewFeedback",
  AddFeedback = "AddFeedback",
  EditFeedback = "EditFeedback",
  DeleteFeedback = "DeleteFeedback",


  // Value Addition
  ViewValueOrOpportunityAction = "ViewValueOrOpportunityAction",

  // Task Management
  ViewTasksProjectWise = "ViewTasksProjectWise",
  ViewTaskDetailsAndLogsAction = "ViewTaskDetailsAndLogsAction",

  // Helpdesk
  AddSupportTicketAction = "AddSupportTicketAction",
  ViewSupportTicket = "ViewSupportTicket",
  EditSupportTicketAction = "EditSupportTicketAction",

  // File Storage
  ViewFileStorage = "ViewFileStorage",
  UploadAFileAction = "UploadAFileAction",
  DeleteAFileAction = "DeleteAFileAction",

  // ProjectReports
  ViewProjectReport = "ViewProjectReport",

  //TMarket
  ViewProductsIntMarket = "ViewProductsIntMarket",
  ViewtMarketProductDetailsAction = "ViewtMarketProductDetailsAction",
  RequestForQuoteIntMarketAction = "RequestForQuoteIntMarketAction",
  ViewtMarketSalesEnquiriesAction = "ViewtMarketSalesEnquiriesAction",
  ViewEnquiryTicketAction = "ViewEnquiryTicketAction",
  AddCommentsInEnquiryAction = "AddCommentsInEnquiryAction",


  // Project Billing
  ViewPmsBilling = "ViewPmsBilling",
  ApproveInvoiceInPmsBillingAction = "ApproveInvoiceInPmsBillingAction",
}
