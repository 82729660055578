import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, Router } from "@angular/router";
import { HeaderService } from "./client/layouts/header/header.service";

@Injectable({
  providedIn: "root",
})
export class RouteGuardService implements CanActivate {
  user: any;

  constructor(private router: Router, private service: HeaderService) {}

  public canActivate(route: ActivatedRouteSnapshot) {
    let user = localStorage.getItem("client_account_type");
    localStorage.getItem("client_access_token");
    if (user) {
      this.service.authenticateUser().then(
        (res: any) => {
          if (res["data"]["bIsQuestionnaireCompleted"] == false) {
            this.router.navigate(["/client/profile"]);
          }
        },
        (err: any) => {
          console.log(err, "errror");
        }
      );
      return true;
    } else {
      this.router.navigate(["/login"]);
      return false;
    }
  }
}
