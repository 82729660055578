import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../../environments/environment";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HeaderService {

  public navCount = new Subject<number>();

  // Create an observable to watch the subject and send out a stream of updates (You will subscribe to this to get the update stream)
  public navCount$ = this.navCount.asObservable(); //Has a $

  // ------ How to update the subject ---------
  // Create a method that allows you to update the subject being watched by observable
  public updateNavItems(newnavCount: number) {
    this.navCount.next(newnavCount);
  }

  public innerNav = new Subject<any[]>();

  // Create an observable to watch the subject and send out a stream of updates (You will subscribe to this to get the update stream)
  public innerNav$ = this.innerNav.asObservable(); //Has a $

  // ------ How to update the subject ---------
  // Create a method that allows you to update the subject being watched by observable
  public updateInnerNav(list: any) {
    this.innerNav.next(list);
  }


  constructor(private http: HttpClient) {}

  // Detect Perfect Scroll End
  // ------ Creating the observable ----------
  // Create a subject - The thing that will be watched by the observable
  public scrollEnd = new Subject<boolean>();

  // Create an observable to watch the subject and send out a stream of updates (You will subscribe to this to get the update stream)
  public scrollEnd$ = this.scrollEnd.asObservable(); //Has a $

  // ------ How to update the subject ---------
  // Create a method that allows you to update the subject being watched by observable
  public updateScrollSubject(newScrollEnd: boolean) {
    this.scrollEnd.next(newScrollEnd);
  }
  // Update it by calling the method..
  // updateScrollSubject('some new string value')


  public localtimezone = new Subject<String>();

  // Create an observable to watch the subject and send out a stream of updates (You will subscribe to this to get the update stream)
  public localtimezone$ = this.localtimezone.asObservable(); //Has a $

  // ------ How to update the subject ---------
  // Create a method that allows you to update the subject being watched by observable
  public updatelocaltimezoneSubject(newlocaltimezone: String) {
    this.localtimezone.next(newlocaltimezone);
  }


  authenticateUser() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/common/v1/login-user-information`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  clientViewProfile() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/client/v1/client-view-profile`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  logoutAPI(data: any) {
    return this.http.post(`${environment.baseUrl}/common/v1/Logout`, data);
  }

  Logout() {
    localStorage.removeItem("client_access_token");
    localStorage.removeItem("client_account_type");
    localStorage.removeItem("client_email");
    localStorage.removeItem("sub_client_account_type");
    localStorage.removeItem("sub_client_email");
    localStorage.removeItem("sub_client_role");
    localStorage.removeItem("client_zendesk_token");
    localStorage.removeItem("client_notification_token");
    localStorage.removeItem("client_path");
    localStorage.removeItem("client_chatUserId");
  }

  loginUserInformation(data: any) {
    return this.http.get(`${environment.baseUrl}/common/v1/login-user-information`, data);
  }

  removeFromCart(iReqCartId: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/client/v1/cart/${iReqCartId}`;
      this.http
        .delete(apiURL, iReqCartId)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  getCartData(iReqId: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/client/v1/cart/${iReqId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  ProceedFurther(data: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/client/v1/team`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetNotification(data: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/common/v1/notification/${data.iUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  NotificationChecked(data: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/common/v1/notification/checked`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetInterviewCount() {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/client/v1/get-interview-count`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetContractCount() {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/client/v1/get-contract-count`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetPMSCount() {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/client/v2/project-count`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetLocalTimeZone(){
    const localtimezone=Intl.DateTimeFormat().resolvedOptions().timeZone;
    return localtimezone;
  }

  LoginSubUserInformation(data: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/common/v2/login-sub-user-information`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }


}
