<!-- <div class="page-content">
  <div class="page-content d-flex flex-column justify-content-center align-items-center text-center w-100 h-100">
    <img [src]="imagePath + 'Site-Under-Schedule.jpg'" alt="under-construction" width= "300">
    <h2 class="no-data-found mb-2">This Page is under construction!</h2>
  </div>
</div>
<app-footer></app-footer> -->

<div class="page-content h-100 site-construction">
  <div class="page-content d-flex flex-column justify-content-center align-items-center text-center w-100 h-100">
    <img src="./assets/images/rapidise-logo.svg" alt="Logo">
    <img class="uc-img" src="./assets/images/Site-Under-Schedule-Maintenance.png" alt="Site-Under-Schedule-Maintenance">
    <span class="uc-text-1">SITE UNDER SCHEDULED</span>
    <span class="uc-text-2">MAINTENANCE</span>
    <!-- <p class="no-data-found-small">Estimated Time</p>
    <div class="timer">
      <div class="d-flex flex-column time-inner-box1">
        <div>{{ days }}</div>
        <div class="sm-text">days</div>
      </div>
      <div class="d-flex flex-column time-inner-box2">
        <div>{{ hours }}</div>
        <div class="sm-text">hours</div>
      </div>
      <div class="d-flex flex-column time-inner-box1">
        <div>{{ minutes }}</div>
        <div class="sm-text">minutes</div>
      </div>
      <div class="d-flex flex-column time-inner-box2">
        <div>{{ seconds }}</div>
        <div class="sm-text">seconds</div>
      </div>
    </div> -->
  </div>
</div>