import { Injectable } from "@angular/core";
import { Permission } from "./../enums/permission.enum";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import * as CryptoJS from 'crypto-js';
import { HeaderService } from "src/app/client/layouts/header/header.service";
import { EventEmitterService } from "src/app/config/event-emitter.service";

const ENCRYPTION_KEY: string = "89701bb10aa7913";

@Injectable({
  providedIn: "root",
})
export class UserPermissionService {
  constructor(private http: HttpClient, private router: Router,private headerService: HeaderService,private eventEmitterService: EventEmitterService , private cookieService: CookieService) { }

  // fill from API
  permissions: Permission[] = [
    // Default Permission
    Permission.AccessDashboard,

    // // Side Menu
    // Permission.AccessDashboard,
    // Permission.AccessContracts,
    // Permission.AccessRequirements,
    // Permission.AccessCalendar,
    // Permission.AccessInterviews,
    // Permission.AccessCompanyProfile,
    // Permission.AccessRoleManagement,
    // Permission.AccessProjects,
    // Permission.AccessRiskManagement,
    // Permission.AccessEscalationManagement,
    // Permission.AccessProjectFeedback,
    // Permission.AccessValueAndOpportunity,
    // Permission.AccessTaskManagement,
    // Permission.AccessHelpdesk,
    // Permission.AccessFileStorage,
    // Permission.AccessProjectReports,
    // Permission.AccessTMarket,
    // Permission.AccessProjectBilling,


    // // Dashboard
    // Permission.ViewDashboard,
    // Permission.ViewProfileAction,
    // Permission.ViewContractAction,
    // Permission.ViewRequirementAction,
    // Permission.ViewInterviewAction,

    // // Contract
    // Permission.ViewContracts,
    // Permission.AccessIndividualContract,
    // Permission.CreateContractAction,
    // Permission.SignContractAction,
    // Permission.PaymentAction,

    // // Requirement
    // Permission.ViewRequirements,
    // Permission.AddRequirementAction,
    // Permission.EditRequirementNameAction,
    // Permission.AddNewTalentInMyTeamAction,
    // Permission.AccessMyTeam,
    // Permission.DeleteTeamMemberInMyTeamAction,
    // Permission.ScheduleInterviewInMyTeamAction,
    // Permission.RecheduleInterviewInMyTeamAction,
    // Permission.CancelInterviewInMyTeamAction,
    // Permission.HireInMyTeamAction,
    // Permission.RejectInMyTeamAction,
    // Permission.SecondRoundInMyTeamAction,
    // Permission.AddContractInMyTeamAction,
    // Permission.AccessParticularRequirement,
    // Permission.ViewEmployeeProfileAction,
    // Permission.AccessCart,

    // // Calendar
    // Permission.ViewCalendar,
    // Permission.ViewInterviewDetailsAction,
    // Permission.ViewContractDetailsAction,

    // // Interview
    // Permission.ViewInterviews,
    // Permission.RescheduleInterviewAction,
    // Permission.AccessVideoConference,
    // Permission.HireAction,
    // Permission.RejectAction,
    // Permission.CancelInterview,
    // Permission.SecondRoundAction,

    // // Company Profile
    // Permission.ViewCompanyProfile,
    // Permission.AccessEditCompanyProfile,
    // Permission.PublishCompanyProfileAction,
    // Permission.EditPrimaryDetailsAction,
    // Permission.EditExecutiveDetailsAction,
    // Permission.EditCompanyDetailsAction,
    // Permission.EditCompanyDocumentsAction,
    // Permission.EditCertificationsAction,

    // // Role Management
    // Permission.ViewRoleManagement,
    // Permission.AddSubUserAction,
    // Permission.EditSubUserAction,
    // Permission.DeleteSubUserAction,
    // Permission.ResendSubUserInviteLinkAction,
    // Permission.ActivateSubUserAction,
    // Permission.DeactivateSubUserAction,


    // // Project Management
    // // Projects
    // Permission.ViewProjects,
    // Permission.ViewProjectDetails,
    // Permission.ViewMilestoneDetails,
    // Permission.ViewTaskDetails,
    // Permission.UpdateStackholderDetails,

    // // Risk Management
    // Permission.AddRiskAction,
    // Permission.ViewRiskAction,
    // Permission.DeleteRiskAction,
    // Permission.EditRiskAction,

    // // Escalation Management
    // Permission.ViewEscalationAction,
    // Permission.AddEscalationAction,
    // Permission.EditEscalationAction,
    // Permission.DeleteEscalationAction,


    // // Project Feedback
    // Permission.ViewFeedback,
    // Permission.AddFeedback,
    // Permission.EditFeedback,
    // Permission.DeleteFeedback,


    // // Value Or Opportunity
    // Permission.ViewValueOrOpportunityAction,

    // // Task Management
    // Permission.ViewTasksProjectWise,
    // Permission.ViewTaskDetailsAndLogsAction,

    // // Helpdesk
    // Permission.AddSupportTicketAction,
    // Permission.ViewSupportTicket,
    // Permission.EditSupportTicketAction,

    // // File Storage
    // Permission.ViewFileStorage,
    // Permission.UploadAFileAction,
    // Permission.DeleteAFileAction,

    // // ProjectReports
    // Permission.ViewProjectReport,

    //  //TMarket
    // Permission.ViewProductsIntMarket,
    // Permission.ViewtMarketProductDetailsAction,
    // Permission.RequestForQuoteIntMarketAction,
    // Permission.ViewtMarketSalesEnquiriesAction,
    // Permission.ViewEnquiryTicketAction,
    // Permission.AddCommentsInEnquiryAction,

    // // Project Billing
    // Permission.ViewPmsBilling,
    // Permission.ApproveInvoiceInPmsBillingAction,
  ];

  public isAuthorizedFor(permission: Permission): boolean {
    return this.permissions.indexOf(permission) >= 0;
  }

  ResetPermissions() {
    this.permissions = [
      // Default Permission
      Permission.AccessDashboard,
    ];
  }

  GetEncryptionKey() {
    return ENCRYPTION_KEY;
  }

  GetCookieData() {
    if (this.cookieService.check("client_user")) {
      let cookieData = this.cookieService.get("client_user");
      const bytes = CryptoJS.AES.decrypt(cookieData, this.GetEncryptionKey());
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
    }
  }

  AssignUserPermissions() {
    let userCookie = this.GetCookieData();
    let userType = localStorage.getItem("client_account_type");
    let userEmail = localStorage.getItem("client_email");
    let subUserRoleId = localStorage.getItem("sub_client_role");
    let subUserType = localStorage.getItem("sub_client_account_type");
    let subUserEmail = localStorage.getItem("sub_client_email");
    let client_chatUserId = localStorage.getItem("client_chatUserId");

    this.ResetPermissions();

    if (subUserRoleId && subUserType && subUserEmail) {
      if (!userCookie || userCookie.loggedInUser != 2 || subUserRoleId != userCookie.roleId || subUserType != userCookie.userType || subUserEmail != userCookie.userEmail || userType != userCookie.userType || userEmail != userCookie.userEmail || client_chatUserId != userCookie.chatId) {
        localStorage.removeItem("client_access_token");
        localStorage.removeItem("client_account_type");
        localStorage.removeItem("client_email");
        localStorage.removeItem("sub_client_account_type");
        localStorage.removeItem("sub_client_email");
        localStorage.removeItem("sub_client_role");
        localStorage.removeItem("client_zendesk_token");
        localStorage.removeItem("client_notification_token");
        localStorage.removeItem("client_path");
        localStorage.removeItem("client_chatUserId");
        this.cookieService.delete("client_user");
        this.router.navigate(["login"]);
        return false;
      }

      let reqJSON = {
        roleId: subUserRoleId,
        userType: subUserType,
      };
      // this.GetUserPermissions(reqJSON).then((result: any) => {
      //   if (result.code == 200) {
      //     result.data.userFeatures.forEach((parentItem: any) => {
      //       this.FillParentPermission(parentItem.vParentFeature);

      //       parentItem.child.forEach((childItem: any) => {
      //         this.FillChildPermission(childItem.vChildFeature);
      //       });
      //     });

      //     if (this.router.url == "/login") {
      //       this.router.navigate(["client"]);
      //     }
      //   }
      // });
      const promises: Promise<void>[] = [];
      const promise = new Promise<void>((resolve) => {
        this.GetUserPermissions(reqJSON).then((result: any) => {
          if (result.code == 200) {
            result.data.userFeatures.forEach((parentItem: any) => {
              this.FillParentPermission(parentItem.vParentFeature);
            });
            result.data.userFeatures.forEach((parentItem: any) => {
              parentItem.child.forEach((childItem: any) => {
                this.FillChildPermission(childItem.vChildFeature);
              });
            });


            if (this.router.url == "/login") {
              this.router.navigate(["client"]);
            }
            resolve(); 
          }
        });
      });
      promises.push(promise);

      Promise.all(promises)
        .then(() => {
          this.headerService.updateNavItems(new Date().valueOf());
          this.eventEmitterService.EmitPemissionsLoaded();
        })
        .catch((error) => {
          console.log(error, "error");
        });
      return true;
    } else {
      if (!userCookie || userCookie.loggedInUser != 1 || userType != userCookie.userType || userEmail != userCookie.userEmail || client_chatUserId != userCookie.chatId) {
        localStorage.removeItem("client_access_token");
        localStorage.removeItem("client_account_type");
        localStorage.removeItem("client_email");
        localStorage.removeItem("sub_client_account_type");
        localStorage.removeItem("sub_client_email");
        localStorage.removeItem("sub_client_role");
        localStorage.removeItem("client_zendesk_token");
        localStorage.removeItem("client_notification_token");
        localStorage.removeItem("client_path");
        localStorage.removeItem("client_chatUserId");
        this.cookieService.delete("client_user");
        this.router.navigate(["login"]);
        return false;
      }

      this.permissions = [];

      // push all permissions for main user login
      this.permissions.push(
        // Side Menu
        Permission.AccessDashboard,
        Permission.AccessContracts,
        Permission.AccessRequirements,
        Permission.AccessCalendar,
        Permission.AccessInterviews,
        Permission.AccessCompanyProfile,
        Permission.AccessRoleManagement,
        Permission.AccessProjects,
        Permission.AccessRiskManagement,
        Permission.AccessEscalationManagement,
        Permission.AccessProjectFeedback,
        Permission.AccessValueAndOpportunity,
        Permission.AccessTaskManagement,
        Permission.AccessHelpdesk,
        Permission.AccessFileStorage,
        Permission.AccessProjectReports,
        Permission.AccessTMarket,
        Permission.AccessProjectBilling,
        

        // Dashboard
        Permission.ViewDashboard,
        Permission.ViewProfileAction,
        Permission.ViewContractAction,
        Permission.ViewRequirementAction,
        Permission.ViewInterviewAction,

        // Contract
        Permission.ViewContracts,
        Permission.AccessIndividualContract,
        Permission.CreateContractAction,
        Permission.SignContractAction,
        Permission.PaymentAction,

        // Requirement
        Permission.ViewRequirements,
        Permission.AddRequirementAction,
        Permission.EditRequirementNameAction,
        Permission.AddNewTalentInMyTeamAction,
        Permission.AccessMyTeam,
        Permission.DeleteTeamMemberInMyTeamAction,
        Permission.ScheduleInterviewInMyTeamAction,
        Permission.RecheduleInterviewInMyTeamAction,
        Permission.CancelInterviewInMyTeamAction,
        Permission.HireInMyTeamAction,
        Permission.RejectInMyTeamAction,
        Permission.SecondRoundInMyTeamAction,
        Permission.AddContractInMyTeamAction,
        Permission.AccessParticularRequirement,
        Permission.ViewEmployeeProfileAction,
        Permission.AccessCart,

        // Calendar
        Permission.ViewCalendar,
        Permission.ViewInterviewDetailsAction,
        Permission.ViewContractDetailsAction,

        // Interview
        Permission.ViewInterviews,
        Permission.RescheduleInterviewAction,
        Permission.AccessVideoConference,
        Permission.HireAction,
        Permission.RejectAction,
        Permission.CancelInterview,
        Permission.SecondRoundAction,

        // Company Profile
        Permission.ViewCompanyProfile,
        Permission.AccessEditCompanyProfile,
        Permission.PublishCompanyProfileAction,
        Permission.EditPrimaryDetailsAction,
        Permission.EditExecutiveDetailsAction,
        Permission.EditCompanyDetailsAction,
        Permission.EditCompanyDocumentsAction,
        Permission.EditCertificationsAction,

        // Role Management
        Permission.ViewRoleManagement,
        Permission.AddSubUserAction,
        Permission.EditSubUserAction,
        Permission.DeleteSubUserAction,
        Permission.ResendSubUserInviteLinkAction,
        Permission.ActivateSubUserAction,
        Permission.DeactivateSubUserAction,

        // Project Management
        // Projects
        Permission.ViewProjects,
        Permission.ViewProjectDetails,
        Permission.ViewMilestoneDetails,
        Permission.ViewTaskDetails,
        Permission.UpdateStackholderDetails,

        // Risk Management
        Permission.AddRiskAction,
        Permission.ViewRiskAction,
        Permission.DeleteRiskAction,
        Permission.EditRiskAction,

        // Escalation Management
        Permission.ViewEscalationAction,
        Permission.AddEscalationAction,
        Permission.EditEscalationAction,
        Permission.DeleteEscalationAction,


        // Project Feedback
        Permission.ViewFeedback,
        Permission.AddFeedback,
        Permission.EditFeedback,
        Permission.DeleteFeedback,


        // Value Or Opportunity
        Permission.ViewValueOrOpportunityAction,

        // Task Management
        Permission.ViewTasksProjectWise,
        Permission.ViewTaskDetailsAndLogsAction,

        // Helpdesk
        Permission.AddSupportTicketAction,
        Permission.ViewSupportTicket,
        Permission.EditSupportTicketAction,

        // File Storage
        Permission.ViewFileStorage,
        Permission.UploadAFileAction,
        Permission.DeleteAFileAction,

        // ProjectReports
        Permission.ViewProjectReport,

        //TMarket
        Permission.ViewProductsIntMarket,
        Permission.ViewtMarketProductDetailsAction,
        Permission.RequestForQuoteIntMarketAction,
        Permission.ViewtMarketSalesEnquiriesAction,
        Permission.ViewEnquiryTicketAction,
        Permission.AddCommentsInEnquiryAction,

        // Project Billing
        Permission.ViewPmsBilling,
        Permission.ApproveInvoiceInPmsBillingAction,
      );
      this.headerService.updateNavItems(new Date().valueOf());
      return true;
    }
  }

  FillParentPermission(vParentFeature: any) {
    switch (vParentFeature) {
      case "Dashboard":
        if (this.permissions.includes(Permission.AccessDashboard)) {
          // dont push dashboard permission
        } else {
          // push dashboard permission
          this.permissions.push(Permission.AccessDashboard);
        }
        break;
      case "Contracts":
        this.permissions.push(Permission.AccessContracts);
        this.permissions.push(Permission.ViewContractAction);
        break;
      case "Requirements":
        this.permissions.push(Permission.AccessRequirements);
        this.permissions.push(Permission.ViewRequirementAction);
        break;
      case "Calendar":
        this.permissions.push(Permission.AccessCalendar);
        break;
      case "Interviews":
        this.permissions.push(Permission.AccessInterviews);
        this.permissions.push(Permission.ViewInterviewAction);
        break;
      case "Company Profile":
        this.permissions.push(Permission.AccessCompanyProfile);
        this.permissions.push(Permission.ViewProfileAction);
        break;
      case "Role Management":
        this.permissions.push(Permission.AccessRoleManagement);
        break;
      case "Projects":
        this.permissions.push(Permission.AccessProjects);
        this.permissions.push(Permission.ViewProjects);
        break;
      case "Risk Management":
        this.permissions.push(Permission.AccessRiskManagement);
        this.permissions.push(Permission.ViewRiskAction);
        if (this.permissions.includes(Permission.AccessProjects)) {
        } else {
          this.permissions.push(Permission.AccessProjects);
        }
        if (this.permissions.includes(Permission.ViewProjects)) {
        } else {
          this.permissions.push(Permission.ViewProjects);
        }
        break;
      case "Escalation Management":
        this.permissions.push(Permission.AccessEscalationManagement);
        this.permissions.push(Permission.ViewEscalationAction);
        if (this.permissions.includes(Permission.AccessProjects)) {
        } else {
          this.permissions.push(Permission.AccessProjects);
        }
        if (this.permissions.includes(Permission.ViewProjects)) {
        } else {
          this.permissions.push(Permission.ViewProjects);
        }
        break;
      case "Project Feedback":
        this.permissions.push(Permission.AccessProjectFeedback);
        this.permissions.push(Permission.ViewFeedback);
        if (this.permissions.includes(Permission.AccessProjects)) {
        } else {
          this.permissions.push(Permission.AccessProjects);
        }
        if (this.permissions.includes(Permission.ViewProjects)) {
        } else {
          this.permissions.push(Permission.ViewProjects);
        }
        break;
      case "Value & Opportunity":
        this.permissions.push(Permission.AccessValueAndOpportunity);
        this.permissions.push(Permission.ViewValueOrOpportunityAction);
        if (this.permissions.includes(Permission.AccessProjects)) {
        } else {
          this.permissions.push(Permission.AccessProjects);
        }
        if (this.permissions.includes(Permission.ViewProjects)) {
        } else {
          this.permissions.push(Permission.ViewProjects);
        }
        break;
      case "Task Management":
        this.permissions.push(Permission.AccessTaskManagement);
        this.permissions.push(Permission.ViewTasksProjectWise);
        if (this.permissions.includes(Permission.AccessProjects)) {
        } else {
          this.permissions.push(Permission.AccessProjects);
        }
        if (this.permissions.includes(Permission.ViewProjects)) {
        } else {
          this.permissions.push(Permission.ViewProjects);
        }
        break;
      case "Helpdesk":
        this.permissions.push(Permission.AccessHelpdesk);
        this.permissions.push(Permission.ViewSupportTicket);
        if (this.permissions.includes(Permission.AccessProjects)) {
        } else {
          this.permissions.push(Permission.AccessProjects);
        }
        if (this.permissions.includes(Permission.ViewProjects)) {
        } else {
          this.permissions.push(Permission.ViewProjects);
        }
        break;
      case "File Storage":
        this.permissions.push(Permission.AccessFileStorage);
        this.permissions.push(Permission.ViewFileStorage);
        if (this.permissions.includes(Permission.AccessProjects)) {
        } else {
          this.permissions.push(Permission.AccessProjects);
        }
        if (this.permissions.includes(Permission.ViewProjects)) {
        } else {
          this.permissions.push(Permission.ViewProjects);
        }
        break;
      case "Project Reports":
        this.permissions.push(Permission.AccessProjectReports);
        this.permissions.push(Permission.ViewProjectReport);
        if (this.permissions.includes(Permission.AccessProjects)) {
        } else {
          this.permissions.push(Permission.AccessProjects);
        }
        if (this.permissions.includes(Permission.ViewProjects)) {
        } else {
          this.permissions.push(Permission.ViewProjects);
        }
        break;
      case "Project Billing":
        this.permissions.push(Permission.AccessProjectBilling);
        this.permissions.push(Permission.ViewPmsBilling);
        if (this.permissions.includes(Permission.AccessProjects)) {
        } else {
          this.permissions.push(Permission.AccessProjects);
        }
        if (this.permissions.includes(Permission.ViewProjects)) {
        } else {
          this.permissions.push(Permission.ViewProjects);
        }
        break;
      case "T Market":
        this.permissions.push(Permission.AccessTMarket);
        this.permissions.push(Permission.ViewProductsIntMarket);
        break;
      default:
        this.permissions.push(Permission.AccessDashboard);
    }
  }

  FillChildPermission(vChildFeature: any) {
    switch (vChildFeature) {
      case "View Dashboard":
        this.permissions.push(Permission.ViewDashboard);
        break;
      case "View Dashboard Profile":
        this.permissions.push(Permission.ViewProfileAction);
        break;
      case "View Dashboard Contracts":
        this.permissions.push(Permission.ViewContractAction);
        break;
      case "View Dashboard Requirements":
        this.permissions.push(Permission.ViewRequirementAction);
        break;
      case "View Dashboard Interviews":
        this.permissions.push(Permission.ViewInterviewAction);
        break;
      case "View Contracts":
        this.permissions.push(Permission.ViewContracts);
        break;
      case "View Contract Details":
        this.permissions.push(Permission.AccessIndividualContract);
        break;
      case "Create Contract":
        this.permissions.push(Permission.CreateContractAction);
        break;
      case "Sign Contract":
        this.permissions.push(Permission.SignContractAction);
        break;
      case "Payment For Contract":
        this.permissions.push(Permission.PaymentAction);
        break;
      case "View Requirements":
        this.permissions.push(Permission.ViewRequirements);
        break;
      case "Create Requirement":
        this.permissions.push(Permission.AddRequirementAction);
        break;
      case "Edit Requirement Name":
        this.permissions.push(Permission.EditRequirementNameAction);
        break;
      case "Add New Talent":
        this.permissions.push(Permission.AddNewTalentInMyTeamAction);
        break;
      case "Access My Team":
        this.permissions.push(Permission.AccessMyTeam);
        break;
      case "Delete Team Member":
        this.permissions.push(Permission.DeleteTeamMemberInMyTeamAction);
        break;
      case "Schedule Interview":
        this.permissions.push(Permission.ScheduleInterviewInMyTeamAction);
        break;
      case "Allow to Rechedule Interview":
        this.permissions.push(Permission.RecheduleInterviewInMyTeamAction);
        break;
      case "Allow to Cancel Interview":
        this.permissions.push(Permission.CancelInterviewInMyTeamAction);
        break;
      case "Allow to Hire":
        this.permissions.push(Permission.HireInMyTeamAction);
        break;
      case "Allow to Reject":
        this.permissions.push(Permission.RejectInMyTeamAction);
        break;
      case "Allow to Schedule 2nd Round Interview":
        this.permissions.push(Permission.SecondRoundInMyTeamAction);
        break;
      case "Allow to Create Contract":
        this.permissions.push(Permission.AddContractInMyTeamAction);
        break;
      case "View Individual Requirement":
        this.permissions.push(Permission.AccessParticularRequirement);
        break;
      case "View Employee Profile":
        this.permissions.push(Permission.ViewEmployeeProfileAction);
        break;
      case "Access Cart":
        this.permissions.push(Permission.AccessCart);
        break;
      case "View Calendar":
        this.permissions.push(Permission.ViewCalendar);
        break;
      case "View Interview Details":
        this.permissions.push(Permission.ViewInterviewDetailsAction);
        break;
      case "View Contract Details":
        this.permissions.push(Permission.ViewContractDetailsAction);
        break;
      case "View Interviews":
        this.permissions.push(Permission.ViewInterviews);
        break;
      case "Reschedule Interview":
        this.permissions.push(Permission.RescheduleInterviewAction);
        break;
      case "Join Video Conference":
        if (this.permissions.includes(Permission.AccessInterviews)) {
          this.permissions.push(Permission.AccessVideoConference);
        }
        break;
      case "Hire Talent":
        this.permissions.push(Permission.HireAction);
        break;
      case "Reject Talent":
        this.permissions.push(Permission.RejectAction);
        break;
      case "Cancel Interview":
        this.permissions.push(Permission.CancelInterview);
        break;
      case "Schedule 2nd Round Interview":
        this.permissions.push(Permission.SecondRoundAction);
        break;
      case "View Company Profile":
        this.permissions.push(Permission.ViewCompanyProfile);
        break;
      case "Edit Company Profile":
        this.permissions.push(Permission.AccessEditCompanyProfile);
        break;
      case "Publish Company Profile":
        this.permissions.push(Permission.PublishCompanyProfileAction);
        break;
      case "Edit Primary Details":
        this.permissions.push(Permission.EditPrimaryDetailsAction);
        break;
      case "Edit Executive Details":
        this.permissions.push(Permission.EditExecutiveDetailsAction);
        break;
      case "Edit Company Details":
        this.permissions.push(Permission.EditCompanyDetailsAction);
        break;
      case "Edit Company Documents":
        this.permissions.push(Permission.EditCompanyDocumentsAction);
        break;
      case "Edit Certification Details":
        this.permissions.push(Permission.EditCertificationsAction);
        break;
      case "View Role Management":
        this.permissions.push(Permission.ViewRoleManagement);
        break;
      case "Add Sub User":
        this.permissions.push(Permission.AddSubUserAction);
        break;
      case "Edit Sub User":
        this.permissions.push(Permission.EditSubUserAction);
        break;
      case "Delete Sub User":
        this.permissions.push(Permission.DeleteSubUserAction);
        break;
      case "Resend Sub User Invite Link":
        this.permissions.push(Permission.ResendSubUserInviteLinkAction);
        break;
      case "Activate Sub User":
        this.permissions.push(Permission.ActivateSubUserAction);
        break;
      case "Deactivate Sub User":
        this.permissions.push(Permission.DeactivateSubUserAction);
        break;
      case "View Projects":
        this.permissions.push(Permission.ViewProjects);
        break;
      case "View Project Details":
        this.permissions.push(Permission.ViewProjectDetails);
        break;
      case "Update Stakeholder Details":
        this.permissions.push(Permission.UpdateStackholderDetails);
        break;
      case "View Milestone Details":
        this.permissions.push(Permission.ViewMilestoneDetails);
        break;
      case "View Task Details":
        this.permissions.push(Permission.ViewTaskDetails);
        break;
      case "Add Risk":
        this.permissions.push(Permission.AddRiskAction);
        break;
      case "View Risk":
        this.permissions.push(Permission.ViewRiskAction);
        break;
      case "Delete Risk":
        this.permissions.push(Permission.DeleteRiskAction);
        break;
      case "Edit Risk":
        this.permissions.push(Permission.EditRiskAction);
        break;
      case "View Escalation":
        this.permissions.push(Permission.ViewEscalationAction);
        break;
      case "Add Escalation":
        this.permissions.push(Permission.AddEscalationAction);
        break;
      case "Edit Escalation":
        this.permissions.push(Permission.EditEscalationAction);
        break;
      case "Delete Escalation":
        this.permissions.push(Permission.DeleteEscalationAction);
        break;
      case "View Feedback":
        this.permissions.push(Permission.ViewFeedback);
        break;
      case "Add Feedback":
        this.permissions.push(Permission.AddFeedback);
        break;
      case "Edit Feedback":
        this.permissions.push(Permission.EditFeedback);
        break;
      case "Delete Feedback":
        this.permissions.push(Permission.DeleteFeedback);
        break;
      case "View Value Or Opportunity":
        this.permissions.push(Permission.ViewValueOrOpportunityAction);
        break;
      case "View Tasks Project Wise":
        this.permissions.push(Permission.ViewTasksProjectWise);
        break;
      case "View Task Details And Logs":
        this.permissions.push(Permission.ViewTaskDetailsAndLogsAction);
        break;
      case "Add Support Ticket":
        this.permissions.push(Permission.AddSupportTicketAction);
        break;
      case "View Support Ticket":
        this.permissions.push(Permission.ViewSupportTicket);
        break;
      case "Edit Support Ticket":
        this.permissions.push(Permission.EditSupportTicketAction);
        break;
      case "View FileStorage":
        this.permissions.push(Permission.ViewFileStorage);
        break;
      case "Upload A File":
        this.permissions.push(Permission.UploadAFileAction);
        break;
      case "Delete A File":
        this.permissions.push(Permission.DeleteAFileAction);
        break;
      case "View Project Report":
        this.permissions.push(Permission.ViewProjectReport);
        break;
      case "View Products In tMarket":
        this.permissions.push(Permission.ViewProductsIntMarket);
        break;
      case "View tMarket Product Details":
        this.permissions.push(Permission.ViewtMarketProductDetailsAction);
        break;
      case "Request For Quote In tMarket":
        this.permissions.push(Permission.RequestForQuoteIntMarketAction);
        break;
      case "View tMarket Sales Enquiries":
        this.permissions.push(Permission.ViewtMarketSalesEnquiriesAction);
        break;
      case "View Enquiry Ticket":
        this.permissions.push(Permission.ViewEnquiryTicketAction);
        break;
      case "Add Comments In Enquiry":
        this.permissions.push(Permission.AddCommentsInEnquiryAction);
        break;
      case "View Billing In Pms":
        this.permissions.push(Permission.ViewPmsBilling);
        break;
      case "Approve Invoice In Pms":
        this.permissions.push(Permission.ApproveInvoiceInPmsBillingAction);
        break;
    }
  }

  GetRoles(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/get-roles/${data.userType}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetUserPermissions(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/get-user-feature?iRoleId=${data.roleId}&tiUserFeatureType=${data.userType}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }
}
