export const environment = {
  dis_alert_timeout: 3000,
  production: true,
  home_page: "https://teksun.rapidise.io",
  baseUrl: "https://back.rapidise.io/api",
  portalUrl: "https://client.teksun.rapidise.io",
  client_login_page: "https://client.teksun.rapidise.io/login",
  image_path: "https://objectapi.teksun.com/rapidise-assets/images/",
  audio_path: "https://objectapi.teksun.com/rapidise-assets/audio/",
  socket_url: "https://back.rapidise.io",
  socket_path: "/socket.io",
  mmUrl: "https://teco.teksun.com/api/v4",
  mm: {
    team_id: "t6umup8nfpby9gi5q11xfm6foy",
  },
  recaptcha: {
    api_key: "6LcGCSYiAAAAACJS-QZpKGsm7LFCNtpXDGKnDWYg",
  },
  zendesk: {
    api_key: "5a7fb19b-194f-4995-a641-9355a7aaa63b",
  },
  amplitude: {
    api_key: "3f97f7ff652d394c022168d87899e05e",
  },
  agora: {
    api_key: "17a402ac8c034c088bce34325c0cf0ca",
  },
  sentry: {
    dsn: "https://7138e9c003c348dcb16478a72d10d55e@o1394344.ingest.sentry.io/6750323",
  },
  firebase: {
    apiKey: "AIzaSyAQZ1_KPgf399hoX-zLAmU3rMoOMb99qeY",
    authDomain: "rapidise-inc-c06d7.firebaseapp.com",
    projectId: "rapidise-inc-c06d7",
    storageBucket: "rapidise-inc-c06d7.appspot.com",
    messagingSenderId: "660410043410",
    appId: "1:660410043410:web:abd162140d06e382a4dccb",
    measurementId: "G-VF647FBVX0",
    vapidKey: "BN-65BGy1GdH9ArDYlKGFDDjCeTa1TOX4JRHZ6ju0sMorutf2qwZV4HNEtI4Iva9GKa9RCirlAy0MEBpevS_d3Q",
  },
  google_tag: {
    gtag_id: "G-1D32EK8WCE",
  },
  crypto_js: {
    key : "Rnm8iLsDRw45kopsKN",
  },
  aws: {
    key_id : "8001ac90-dd02-44fd-99fe-00ae40b78b2e",
  },
  aws_keys:{
    set_zero:[
      {
      ACCESSKEYID: "AKIA6H65CGD7JUY2YIVV",
      SECRETACCESSKEY: "A4FFCVq-sf/pODrFH5VTdA8Kd+9QLq3t3AsXYOKo",
      },
      {
      ACCESSKEYID: "ASIA2J76AFB8WKZ3FUCE",
      SECRETACCESSKEY: "K3JVBFa+cs/UpsFsfE3sDc5DcS9QDqse4SsGsLWC",
      },
      {
      ACCESSKEYID: "AKIA4U47CDD8VBY6JAKL",
      SECRETACCESSKEY: "J4DFYsv-sp/A8dEfKb23Fa4Sd3-acWWx35sNSdaTe",
      },
      {
      ACCESSKEYID: "ASIA3G38XBB7WRF3M4SN",
      SECRETACCESSKEY: "N4dfgHe-dj/rfxSxaS3SdCfLla8EFqwd6f7KsyTR",
      },
      {
      ACCESSKEYID: "ASIA1D8GmN3EsHY2LIYB",
      SECRETACCESSKEY: "L2lgaJs+me/RsdFtHLXsaEd8l52SqWsy4JvJsxWM",
      },
    ],
    set_one:[
      {
      ACCESSKEYID: "ASIA2J76AFB8WKZ3FUCE",
      SECRETACCESSKEY: "J2DHCEq+es/13jnDM3LF2R5H+fe8M4ak4EDCUmeY",
      },
      {
      ACCESSKEYID: "ASIA1D8GmN3EsHY2LIYB",
      SECRETACCESSKEY: "L4RFCSu-hl/OPF23LcDDb5fLa-34ff5gdECdSxqL",
      },
      {
      ACCESSKEYID: "ASIA3G38FBB3WRM3M4SN",
      SECRETACCESSKEY: "M1SDXDw+fu/IdZeSA4LM5LsL-sD4Lsfg2EsLKsgI",
      },
      {
      ACCESSKEYID: "AKIA4U47CDD8VBY6JAKL",
      SECRETACCESSKEY: "I2DSL3q-dp/UOCLsE56Ls2WL-sa85Kemw36SlsfP",
      },
      {
      ACCESSKEYID: "AKIA6H65CGD7JUY2YIVV",
      SECRETACCESSKEY: "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",
      },
    ],
    set_two:[
      {
      ACCESSKEYID: "ASIA3G38FBB3WRM3M4SN",
      SECRETACCESSKEY: "I5LsMw3+lw/LowcDL1nMdA8HtYkSDh+yLeRTjmYL",
      },
      {
      ACCESSKEYID: "AKIA4U47CDD8VBY6JAKL",
      SECRETACCESSKEY: "K2sCs2Q-sd/WqeRsx5wCaM8Kd5-JksJyusN+rWJd",
      },
      {
      ACCESSKEYID: "AKIA6H65CGD7JUY2YIVV",
      SECRETACCESSKEY: "A5DFL2d-pq/NOsxEMsLTelO6n+9QLpEk-Kl4nMjS",
      },
      {
      ACCESSKEYID: "ASIA2J76AFB8WKZ3FUCE",
      SECRETACCESSKEY: "H8dMd7w+en/eOIsCd1VTI+sIBFdhVq3t3AsdYsNM",
      },
      {
      ACCESSKEYID: "ASIA1D8GmN3EsHY2LIYB",
      SECRETACCESSKEY: "E6FDn2w-ws/ReDjtK3ABRs+4PU9BdgEklesXYlwT",
      },
    ],
  }
};
