import { Component, OnInit } from "@angular/core";
import { AppService } from "./app.service";
import { UserPermissionService } from "./utils/services/user-permission.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  title = "Rapidise - Client";

  constructor(private appService: AppService, private userPermissionService: UserPermissionService) {
    let accessToken = localStorage.getItem("client_access_token");
    
    // if (accessToken) {
    //   this.AssignUserPermissions();
    // }
  }

  ngOnInit() {
    this.CreateRequiredHeadTags();
  }

  CreateRequiredHeadTags() {
    this.appService.CreateLinkTagsForGoogleFont();
    this.appService.CreateScriptTagForAmplitude();
    this.appService.CreateScriptTagForZendesk();
    this.appService.CreateScriptTagForGoogleTag();
  }

  // AssignUserPermissions() {
  //   this.userPermissionService.AssignUserPermissions();
  // }
}
