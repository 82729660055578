import { Injectable } from "@angular/core";
import { init, setSessionId, reset, track } from "@amplitude/analytics-browser";
import { environment } from "./../../environments/environment";

@Injectable({
  providedIn: "root",
})
export default class Amplitude {
  public Initialize() {
    let useEmail = localStorage.getItem("client_email");

    // initialize amplitude
    init(environment.amplitude.api_key, useEmail!, {
      trackingOptions: {
        deviceManufacturer: true,
        deviceModel: true,
        ipAddress: true,
        language: true,
        osName: true,
        osVersion: true,
        platform: true,
      },
      attribution: {
        trackPageViews: true,
      },
    });

    // set session id
    setSessionId(Date.now());

    return true;
  }

  public Reset() {
    // reset amplitude
    reset();

    return true;
  }

  public LogUserEvent(eventType: string, eventProperties: object) {
    if (!eventProperties || eventProperties == null) {
      track(eventType);
    } else {
      track(eventType, eventProperties);
    }

    return true;
  }
}
