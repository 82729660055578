import { Component, OnInit } from '@angular/core';
import { environment } from "./../../environments/environment";

@Component({
  selector: 'app-site-construction',
  templateUrl: './site-construction.component.html',
  styles: [
  ]
})
export class SiteConstructionComponent implements OnInit {
  imagePath = environment.image_path;

  endDate:any;
  today:any;
  timeLeft:any;
  timer:any;
  days:any;
  hours:any;
  minutes:any;
  seconds:any;
  constructor() {}

  ngOnInit(): void {

    this.today=new Date();
    this.endDate = new Date('2022-12-21T12:30:00Z'); // end date in UTC time
    this.timeLeft = (this.endDate - this.today); // time left in milliseconds
    this.timer = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft -= 1000; // decrement by 1 second
        let temptime=this.convertMillisecondsToHumanReadable(this.timeLeft);
        this.days=temptime.days;
        this.hours=temptime.hours;
        this.minutes=temptime.minutes;
        this.seconds=temptime.seconds;
      } else {
        clearInterval(this.timer);
        this.days=0;
        this.hours=0;
        this.minutes=0;
        this.seconds=0;
      }
      // update the UI with the new time left value
    }, 1000); // 1000ms = 1 second
  }

  convertMillisecondsToHumanReadable(milliseconds:any) {
    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
  
    return {
      days,
      hours,
      minutes,
      seconds
    };
  }


}
