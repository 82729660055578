import { Injectable, EventEmitter } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class EventEmitterService {
  EmpolyeeData = new EventEmitter();
  modelMessageData = new EventEmitter();
  AuthenticateUser = new EventEmitter();
  GetProfileStatusAPISubMethod = new EventEmitter();
  GetProfileStatusSubMethod = new EventEmitter();
  MenuListSubMethod = new EventEmitter();
  GetDynamicDoucmentsSubMethod = new EventEmitter();
  CartData = new EventEmitter();
  getRequirementID = new EventEmitter();
  getCartDataRemoved = new EventEmitter();
  PemissionsLoaded = new EventEmitter();

  public userdata: any;

  EmitEmpolyeeData(data: any) {
    this.EmpolyeeData.emit(data);
  }

  modelMessage(data: any) {
    this.modelMessageData.emit(data);
  }

  callAuthenticateUser() {
    this.AuthenticateUser.emit();
  }

  GetProfileStatusAPICallMethod() {
    this.GetProfileStatusAPISubMethod.emit();
  }

  GetProfileStatusCallMethod(data: any) {
    this.GetProfileStatusSubMethod.emit(data);
  }

  MenuListCallMethod() {
    this.MenuListSubMethod.emit();
  }

  GetDynamicDoucmentsCallMethod() {
    this.GetDynamicDoucmentsSubMethod.emit();
  }

  CartDataList(userdata: any) {
    // userdata = JSON.parse(localStorage.getItem("userData") || '{}');
    this.CartData.emit(userdata);
  }

  GetRequirementID(requirementID: any) {
    this.getRequirementID.emit(requirementID);
  }

  CartDataRemoved() {
    this.getCartDataRemoved.emit();
  }

  EmitPemissionsLoaded() {
    this.PemissionsLoaded.emit();
  }
}
